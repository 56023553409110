.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.App-header {
  flex-shrink: 0;
}

.body-container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.mobileOnly {
  display: none;
}

button.mobileOnly {
  display: none;
}

.left-navigation {
  width: auto;
  overflow-y: scroll;
  border-right: 1px solid #ccc;
  padding: 20px;

}

@media (max-width: 767px) {
  .left-navigation {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 1000;
    overflow-y: scroll;
    border-right: 1px solid #ccc;
    padding: 20px;
    transform: translateX(-100%);
    /* Start off the screen */
    transition: transform 0.3s ease-in-out;
    /* Animation effect */

  }

  button.mobileOnly {
    display: inline;
  }

  .mobileOnly {
    display: block;
  }


  .left-navigation.open {
    width: 100%;
    transform: translateX(0);
    /* Bring back on the screen */

  }
}







.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: scroll;
}

.navigation-items h4 {
  margin-top: 20px;
}

.navigation-items .navigation-link {

  background-color: #0a2a52;
  /* Change this to your preferred color */
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.navigation-items .nav-link:hover {
  background-color: #00688B;
  /* Darker shade for hover */
}

.navigation-items div {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}


Footer {
  color: white;
  background-color: #0a2a52;
  position: sticky;
  width: 100%;
  bottom: 0;
  justify-content: center;
  flex-shrink: 0;
  /* Prevent footer from shrinking */

}

header,
footer {
  flex-shrink: 0;
  /* Prevents header and footer from shrinking */
  background-color: #0a2a52;
  /* Add a background color if desired */
}

.user-account-menu .nav-item {
  background-color: #0a2a52;
  color: white;
}

.user-account-menu {
  display: flex;
  align-items: center;
  margin-right: auto;
  font-size: large;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-direction: column;
}

.center-container {
  display: flexbox;
  align-items: center;
  justify-content: center;
}