.product-card {
    width: 340px;
    height: 450px;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    margin: 10px;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.product-title {
    font-size: 20px;
    text-align: center;
    word-wrap: break-word;
}

.product-description {
    margin: 20px 0;
    word-wrap: break-word;
}

.price-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.view-features {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #0a2a52;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    bottom: 0;
    margin-top: auto; /* This pushes the button to the bottom of the flex container */
}
.products-container > div {
    flex: 0 0 auto;          /* Do not allow the cards to grow or shrink */
    width: 340px;            /* Example fixed width, adjust as needed */
    height: 450px;           /* Example fixed height, adjust as needed */
  }
  .products-container {
    display: flex;           /* Set up a flex container */
    overflow-x: auto;        /* Allow horizontal scrolling */
    white-space: wrap;     /* Prevent wrapping on smaller screens */
    gap: 1rem;               /* Optional: A gap between each card */
  }
  .mobileOnly{
    display: none;
  }
  @media (max-width: 767px){
    .desktopOnly{
        display: none;
    }
    .mobileOnly{
        display: block;
    }
  }