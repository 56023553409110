.mobile-ui{
    display: none;
}
.profile-image{
    width: 100%;
    position: relative;
}
@media (max-width: 767px) {
    .profile-image{
        width: 75%;
        position: relative;
    }
    .mobile-ui{
        display: block;
    }
    .desktop-ui{
        display: none;
    }
}