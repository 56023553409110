.day-slot-holder{
    display: flex;
    justify-content: 'space-between';
    flex-direction: column;
    align-items: center;
    border-radius: 3%;
};
.filter-component{
    margin-Bottom: '20px';
    display: 'flex';
    justify-Content: 'right';
    align-Items: 'center';
    gap: '10px';
}
.appointment-style-holder p, 
.appointment-style-holder small {
    margin: 2px 0;    /* Adjust as needed for vertical spacing */
    line-height: 1.2; /* Adjust as needed for line spacing */
}
.products-container {
    display: flex;           /* Set up a flex container */
    width: 100%;
    overflow-x: auto;        /* Allow horizontal scrolling */
    white-space: wrap;     /* Prevent wrapping on smaller screens */
    gap: 1rem;               /* Optional: A gap between each card */
  }