.footer-container {
    width: 100%;
  }
  
  .button-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: auto; /* or another value that suits the design */
    padding: 20px 0; /* Adjust as needed */
    
  }
  
  .button-col {
    /* Adjust styles as needed */
  }
  
  button.icon-button {
    border: 2px solid #000; /* or another color */
    color: #0a2a52;
    padding: 10px;
    border-radius: 5px; /* or another value that suits the design */
    transition: background-color 0.3s ease;
    
  }
  
  .icon-button:hover {
    background-color: #ffffff; /* or another color */
  }
  
  .footer-disclaimer{
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 7px 0; /* Adjust as needed */
    color: #fff; /* or another color */
  }
  .tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #0a2a52; /* Adjust to match the color in your screenshot */
    padding: 10px 0;
}

.tab-button {
    background: none;
    height: 100%;
    border: none;
    color: transparent; /* Adjust as needed */
    font-size: 16px; /* Adjust as needed */
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.3s;
}
button.tab-button{
    background-color: #0a2a52;

}

.tab-button.selected {
    background-color: #f7f7f756; /* Adjust to a lighter shade to indicate selection */
}

.organizations-button {
    background: transparent;
    
    border: none;
    color: transparent;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
}

.white-icon{
    color:white;
}
/* Add hover effects or any other desired styles as needed */

  